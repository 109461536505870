import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
import { TwoxTwoWH , TwoxTwoTitle, TwoxTwoVolanta, backgroundPhoto } from "../mixins-modules"; 


export const ContentTwoxTwoFoto = styled.div`
  ${TwoxTwoWH}
  .contentVideo{
      height:auto;
      max-height: 315px;
      aspect-ratio:16/9;
      min-height:315px;
      ${breakpoints.phone} {
        min-height:180px;
      }

    }
  background:${Color.greyf1f1f1};
  ${breakpoints.darkMode} {
    [data-theme='dark'] & {
    background:${ColorDark.darkGrey};
    }
}
  picture{
    &::before{
      ${backgroundPhoto}
      }
  }
    .mt {
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 70%;
      height: 100%;
      ${breakpoints.phone} {
        min-height:212px;
        padding:20px 0;
      }
      h2.title, .volanta{
        font-size:  36px;
        line-height: 40px;
        ${Font.clarinVarSub2Regular}
        ${breakpoints.phone} {
            font-size: 26px;
            line-height: 30px;
        }
      }
      
    }
  
`;
