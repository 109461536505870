import { ContentTwoxTwoFoto } from "./styles";
import { NormalArticle } from "@/widgets/Nota/Templates/stylesModulesGenericNormal.js";
import ContentTitle from "@/shared/ContentTitle";
import ContentImage from "@/shared/ContentImage";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import VideoLoop from "@/shared/VideoLoop";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import useNewsDataModules from "@/hooks/useNewsDataModules";
const TwoxTwoFotoGrey = (props) => {
  const {
    asPath,
    author = [],
    authorSignature,
    containerName,
    iconMultimedia,
    imagePreview,
    imageWeb,
    isPreview,
    payWallExclusive,
    section = [],
    subtitle,
    title,
    url,
    urlTarget,
    videosOpenLoop,
    videoLoop
  } = useNewsDataModules(props, {
    desktop: ["rectangle_lg"],
    mobile: ["square_md"],
  });  
  return (
    <NormalArticle>
      <ContentTwoxTwoFoto>
        <ContentTitle
          author={author}
          authorSignature={authorSignature}
          title={title}
          subtitle={subtitle}
          section={section}
          payWallExclusive={payWallExclusive}
        />
        {videosOpenLoop?.enabled ? 
        <VideoLoop videoLoop={videoLoop} />
        : <picture>
          {isPreview ? (
            imagePreview && <ContentImage iconMultimedia={iconMultimedia} data={imagePreview} alt={title} />
          ) : (
            <ContentImage iconMultimedia={iconMultimedia} data={imageWeb} alt={title} />
          )}
          <p className="epigraphe">{imageWeb.epigraphe}</p>
        </picture>}
        <LinkAbsolute href={url} target={urlTarget} aria-label={title} onClick={() => clickContent({contentType: title, contentList: containerName},url,asPath)}/>
      </ContentTwoxTwoFoto>
    </NormalArticle>
  );
};

export default TwoxTwoFotoGrey;
